const Soon = () => {
    return (
      <div className="container">
        <span className="soon-text">
          Coming Soon! 😎😉
        </span>

        <span className="footer-text">
            &copy; evfrndly.com
        </span>
      </div>
    );
  }
  
  export default Soon;
  